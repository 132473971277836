var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('nav',{class:{ 'sidebar-bg': !_vm.hideNav },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"flex flex-col h-100 list-unstyled components",class:{ 'hide-nav': _vm.hideNav }},[_c('div',{staticClass:"sidebar-bg sidebar-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/monogram-white.svg"),"width":"42","height":"42","alt":"Rafflebox logo icon"}})])],1),(_vm.canViewDashboard)?_c('router-link',{attrs:{"to":"/","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Dashboard","active":isActive,"navigate":navigate,"href":href,"icon":"fa-gauge-high"}}):_vm._e()]}}],null,false,2814545275)}):_vm._e(),(_vm.showAdmin)?_c('router-link',{attrs:{"to":"/admin","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Admin","icon":"fa-shield-halved","active":isActive,"navigate":navigate,"href":href}}):_vm._e()]}}],null,false,437151778)}):_vm._e(),(_vm.showRaffles)?_c('router-link',{attrs:{"to":"/raffles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Raffles","href":href,"active":isActive,"navigate":navigate,"icon":"fa-tags"}}):_vm._e()]}}],null,false,634809701)}):_vm._e(),(_vm.canViewGoldrushRaffles)?_c('router-link',{attrs:{"to":"/goldrush"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Goldrush","href":href,"active":isActive,"navigate":navigate,"icon":"fa-ticket"}}):_vm._e()]}}],null,false,2657001141)}):_vm._e(),(_vm.canViewOrders)?_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Orders","href":href,"active":isActive,"navigate":navigate,"icon":"fa-dollar-sign"}}):_vm._e()]}}],null,false,3174091190)}):_vm._e(),(_vm.canViewDonations)?_c('router-link',{attrs:{"to":"/donations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Donations","href":href,"active":isActive,"navigate":navigate,"icon":"fa-circle-dollar-to-slot"}}):_vm._e()]}}],null,false,2283972734)}):_vm._e(),(_vm.canViewCustomers)?_c('router-link',{attrs:{"to":"/customers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Customers","href":href,"active":isActive,"navigate":navigate,"icon":"fa-users"}}):_vm._e()]}}],null,false,2547380856)}):_vm._e(),(_vm.canViewStations)?_c('router-link',{attrs:{"to":"/stations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Stations","href":href,"active":isActive,"navigate":navigate,"icon":"fa-location-dot"}}):_vm._e()]}}],null,false,3931552427)}):_vm._e(),(_vm.canViewDevices)?_c('router-link',{attrs:{"to":"/devices"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Devices","href":href,"active":isActive,"navigate":navigate,"icon":"fa-tv"}}):_vm._e()]}}],null,false,3189313988)}):_vm._e(),(_vm.showReports)?_c('router-link',{attrs:{"to":"/reports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Reports","href":href,"active":isActive,"navigate":navigate,"icon":"fa-chart-bar"}}):_vm._e()]}}],null,false,2867247616)}):_vm._e(),(_vm.showMessageCenter)?_c('router-link',{attrs:{"to":"/message-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Message Center","href":href,"active":isActive,"navigate":navigate,"icon":"fa-envelope"}}):_vm._e()]}}],null,false,3803108643)}):_vm._e(),(_vm.canViewUsers)?_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Users","href":href,"active":isActive,"navigate":navigate,"icon":"fa-users"}}):_vm._e()]}}],null,false,3525936781)}):_vm._e(),(_vm.canViewImports)?_c('router-link',{attrs:{"to":"/imports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Imports","href":href,"active":isActive,"navigate":navigate,"icon":"fa-file-import"}}):_vm._e()]}}],null,false,3862093173)}):_vm._e(),(_vm.canViewExports)?_c('router-link',{attrs:{"to":"/exports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Exports","href":href,"active":isActive,"navigate":navigate,"icon":"fa-file-export"}}):_vm._e()]}}],null,false,1540533461)}):_vm._e(),_c('router-link',{staticClass:"mt-auto",attrs:{"to":_vm.url,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(!_vm.hideNav)?_c('SidebarButton',{attrs:{"title":"Knowledge Base","href":_vm.url,"active":isActive,"icon":"fa-circle-question","external":"_blank"}}):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }