import DeviceServiceV2 from '@/lib/device-service-v2';
import StationService from '@/lib/station-service';
const orders = {
  state: () => ({
    form: {
      input: '',
      filter: {
        refunds: true,
        status: null,
        event: null,
        startDateTime: null,
        endDateTime: null,
        station: null,
        device: null,
        batchId: null
      }
    },
    statuses: [
      { name: 'Active', value: 'ACTIVE' },
      { name: 'Pending', value: 'PENDING' },
      { name: 'Error', value: 'FAILED' },
      { name: 'Void', value: 'VOID' }
    ],
    devices: [],
    stations: []
  }),
  getters: {
    getForm: (state) => state.form,
    getSearchInput: (state) => state.form.input,
    getStatuses: (state) => state.statuses,
    getDevices: (state) => state.devices,
    getStations: (state) => state.stations,
    getFilters: (state) => state.form.filter
  },
  actions: {
    setFilters({ commit }, payload) {
      commit('setFilters', payload);
    },
    setSearchInput({ commit }, payload) {
      commit('setSearchInput', payload);
    },
    setForm({ commit }, payload) {
      commit('setForm', payload);
    },
    fetchDevices: async ({ commit }, organizationId) => {
      const devices = [];

      const params = {
        pageSize: 50,
        organizationId
      };

      try {
        const response = await DeviceServiceV2.listDevices(params);

        response.data.forEach((device) => {
          devices.push({ name: device.name, value: device.id });
        });

        commit('setDevices', devices);
      } catch (error) {
        console.log(error);
      }
    },
    fetchStations: async ({ commit }) => {
      const stations = [];

      const params = {
        pageSize: 50
      };

      try {
        const response = await StationService.listStations(params);

        response.data.forEach((station) => {
          stations.push({ name: station.name, value: station.id });
        });

        commit('setStations', stations);
      } catch (error) {
        console.log(error);
      }
    }
  },
  mutations: {
    setFilters(state, payload) {
      state.form.filter = { ...state.form.filter, ...payload };
    },
    setSearchInput(state, payload) {
      state.form.input = payload;
    },
    setForm(state, payload) {
      state.form = { ...state.form, ...payload };
    },
    setDevices(state, payload) {
      state.devices = payload;
    },
    setStations(state, payload) {
      state.stations = payload;
    }
  }
};

export default orders;
