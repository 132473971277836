<template>
  <BarChart
    title="Total Donation by Month - Last 12 Months"
    :fetchData="getSalesReport"
    :series="series"
    chartId="totalDonationsLast12Months"
    csvFilename="Total Donations by Month - Last 12 Months"
    headerCategory="Month"
    @error="handleError"
  />
</template>

<script>
import ReportService from '@/lib/report-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import BarChart from '@/components/ui/BarChart.vue';

export default {
  components: {
    BarChart
  },
  data() {
    return {
      chartData: [],
      errorMessage: null,
      series: []
    };
  },
  methods: {
    async getSalesReport() {
      try {
        this.loading = true;
        this.organizationId = (await getAuth().sessionUser()).organizationUuid;
        const result = await ReportService.retrieveDonationSalesByMonthReport({ orgId: this.organizationId });

        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];

        const mappedReportData = result.data.map(({ monthCreated: x, totalCents: y }) => {
          const date = new Date(x.toString());

          // Get the correct month in UTC
          const formattedX = months[date.getUTCMonth()];
          const formattedY = y / 100;

          return { y: formattedY, x: formattedX };
        });

        // Update chart data
        this.series = [{ name: 'Amount Sold', data: mappedReportData }];
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loading = false;
      }
    },
    handleError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  }
};
</script>
