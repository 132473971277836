<template>
  <div v-if="flagsResolved">
    <div class="grid grid-cols-1 xxl:grid-cols-3 sm:gap-6">
      <div class="col-span-1">
        <div>
          <DashboardSalesReports />
        </div>
      </div>
      <div class="col-span-2 pt-8 xl:p-0">
        <!-- div for metabase replacement charts -->
        <div>
          <TotalSalesReport />
        </div>
        <div><TotalSalesByMonthReport /></div>
        <div v-if="canViewDonationReport"><TotalDonationsByMonthReport /></div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSalesReports from './reports/DashboardSalesReports.vue';
import TotalSalesReport from './reports/TotalSalesReport.vue';
import { productEnabled, Products } from '@/lib/products';
import TotalSalesByMonthReport from './reports/TotalSalesByMonthReport.vue';
import TotalDonationsByMonthReport from './reports/TotalDonationsByMonth.vue';

export default {
  components: {
    DashboardSalesReports,
    TotalSalesReport,
    TotalSalesByMonthReport,
    TotalDonationsByMonthReport
  },
  data() {
    return {
      salesSummaryUrl: null,
      showDashboardComponents: false,
      canViewDonationReport: false,
      flagsResolved: false
    };
  },
  async created() {
    this.showDashboardComponents = true;
  },
  async mounted() {
    this.canViewDonationReport = productEnabled(Products.Donations);
    this.flagsResolved = true;
  }
};
</script>

<style></style>
