import store from '@/store/index.js';

const Products = {
  Donations: 'RFB-DON-001'
};

const productEnabled = (sku) => {
  const enabledProducts = store.getters.enabledProducts;

  return enabledProducts.includes(sku);
};

export { Products, productEnabled };
